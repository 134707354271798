
export default{
    namespaced: true,
    state:{
        login_Details:{},
        permissions:{}
    },
    getters: {
        loginDetails(state){
            return state.login_Details;
        },
        permissions(state){
            return state.permissions;
        }
    },
    mutations: {
        loginDetails(state,data){
            state.login_Details=data;
        },
        permissions(state,data){
            state.permissions=data;
        }
    },
    actions: {
        loginDetails({commit},data){
            commit('loginDetails',data)
        },
        setPermissions({commit},data){
            commit('permissions',data);
        }
    }
}