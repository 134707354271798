import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueToastr from "vue-toastr";
import { store } from "./store/store";

Vue.config.productionTip = false;
//toastr plugin
Vue.use(VueToastr,{
  defaultStyle:{"opacity":"90%","padding-top-bottom":"20px"}
})
//matrial-icons
import '@mdi/font/css/materialdesignicons.css'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
//event bus
export const eventBus = new Vue();
new Vue({
  router,
  vuetify,
  store: store,
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  render: (h) => h(App),
}).$mount("#app");
