<template>
  <v-container fluid>
    <v-row>
      <!-- navbar -->
      <v-app-bar
          absolute
          color="white"
      >
        <v-app-bar-nav-icon @click.stop="drawer=!drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>Loan Management System</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon class="" @click="logout()">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>
      <!-- sidebar -->
      <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary
      >
      <side-bar :logedinUser="loginDetails.name"></side-bar>
      </v-navigation-drawer>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex"
import SideBar from './sidebar.vue'
export default {
  components:{
    SideBar
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    ...mapGetters('Login', ['loginDetails'])
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      console.log('token', localStorage.getItem("token"));
      window.location.href = "/#/login";
    }
  }
}
</script>
