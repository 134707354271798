import Vue from "vue";
import VueRouter from "vue-router";
import dashboardLayout from "@layouts/layout.vue";
import NotFound from "@pages/general/page-not-found";
import {store} from "@/store/store";
Vue.use(VueRouter);
let permission = store.getters['Login/permissions']
const role=localStorage.getItem('user_role');
const routes = [
  {
    path: "/",
    component: dashboardLayout,
    redirect:'dashboard',
    children:[
      {
        path: "dashboard",
        name: "Dashboard",
        component:()=> import('@pages/admin/dashboard/dashboard.vue')
      },
      {
        path:'debits',
        name:"Debits",
        component: ()=> import("@pages/admin/debits/add-debits.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.transaction)
            next('/page-not-found')
          else next()
        }
      },
      {
        path: "people",
        name:"People",
        component: ()=> import("@pages/admin/people/people-list.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.people)
            next('/page-not-found')
          else next()
        }
      },
      {
        path:"reports",
        name:"Reports",
        component: ()=> import("@pages/admin/reports/reports-list.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.report)
            next('/page-not-found')
          else next()
        }
      },
      {
        path:"addpeople",
        name:"Add People",
        component: ()=> import("@pages/admin/people/add-people.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.people)
            next('/page-not-found')
          else next()
        }
      },
      {
        path:"people/update/:id",
        name:"Update People",
        component: ()=> import("@pages/admin/people/update-people.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.people)
            next('/page-not-found')
          else next()
        }

      },
      {
        path:"people/statement/:id",
        name:"Statement",
        component: ()=> import("@pages/admin/people/statement.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.people)
            next('/page-not-found')
          else next()
        }
      },
      {
        path: "addproject",
        name: "Add Project",
        component: ()=> import("@pages/admin/projects/add-project.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.project)
            next('/page-not-found')
          else next()
        }
      },
      {
        path: "projects",
        name: "Projects",
        component: ()=> import("@pages/admin/projects/projects-list.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.project)
            next('/page-not-found')
          else next()
        }
      },
      {
        path: "projects/detail/:id",
        name: "Project Detail",
        component: ()=> import("@pages/admin/projects/project-details.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.project)
            next('/page-not-found')
          else next()
        }
      },
      {
        path: "edit/project/:id",
        name: "Edit Project",
        component: ()=> import("@pages/admin/projects/update-project.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.project)
            next('/page-not-found')
          else next()
        }
      },
      {
        path: "addinvestment",
        name: "Add Investment",
        component: ()=> import("@pages/admin/investments/add-investment.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.investment)
            next('/page-not-found')
          else next()
        }
      },
      {
        path: "investments",
        name: "Investments",
        component: ()=> import("@pages/admin/investments/investment-list.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.investment)
            next('/page-not-found')
          else next()
        }
      },
      {
        path: "edit/investment/:id",
        name: "Edit Investment",
        component: ()=> import("@pages/admin/investments/update-investment.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.investment)
            next('/page-not-found')
          else next()
        }
      },
      {
        path: "addinvestmentreturn",
        name: "Add Investment Return",
        component: ()=> import("@pages/admin/return-of-investments/add-roi.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.return_of_investment)
            next('/page-not-found')
          else next()
        }
      },
      {
        path: "edit/investment/return/:id",
        name: "Edit Investment Return",
        component: ()=> import("@pages/admin/return-of-investments/update-roi.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.return_of_investment)
            next('/page-not-found')
          else next()
        }
      },
      {
        path: "investmentreturn",
        name: "Investment Return",
        component: ()=> import("@pages/admin/return-of-investments/roi-list"),
        beforeEnter:(to,from,next)=>{
          if(role==='2' && !permission.return_of_investment)
            next('/page-not-found')
          else next()
        }
      },
      {
        path: "user/create",
        name: "User Create",
        component: ()=> import("@pages/admin/user/user-create.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='1')
            next()
          else next('/page-not-found')
        }
      },
      {
        path: "user/edit/:id",
        name: "User Edit",
        component: ()=> import("@pages/admin/user/user-update.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='1')
            next()
          else next('/page-not-found')
        }
      },
      {
        path: "users",
        name: "User",
        component: ()=> import("@pages/admin/user/user-list.vue"),
        beforeEnter:(to,from,next)=>{
          if(role==='1')
            next()
          else next('/page-not-found')
        }
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@pages/authorization/Login.vue"),
  },
  { path: "*", component: NotFound }
];

const router = new VueRouter({
  routes,
});

export default router;
