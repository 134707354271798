<template>
    <div>
        <v-list-item>
          <v-list-item-avatar>
            <v-img src="../../assets/User-Avatar-in-Suit-PNG.png"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{logedinUser}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <div class="font-weight-regular text--secondary ml-4 mt-4">Loan Management</div>
        <v-list dense>
          <v-list-item
              v-for="item in loanOptions"
              :key="item.title"
              link :to="item.to" color="#01579B"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="font-weight-regular text--secondary ml-4">Investment Management</div>
        <v-list dense>
          <v-list-item
              v-for="item in investmentOptions"
              :key="item.title"
              link :to="item.to" color="#01579B"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div v-if="role==='1'">
          <div class="font-weight-regular text--secondary ml-4">User Management</div>
          <v-list dense>
            <v-list-item
                link to="/users" color="#01579B"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Users</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list> 
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        logedinUser:[String]
    },
    data(){
        return{
            show: false,
            items: [
                {title: 'Dasboard', icon: 'mdi-view-dashboard-outline', to: "/dashboard",},
                {title: 'People', icon: 'mdi-account-multiple-outline', to: "/people",},
                {title: 'Add Debits', icon: 'mdi-credit-card-outline', to: "/debits",},
                {title: 'Reports', icon: 'mdi-clipboard-outline', to: "/reports",},
                {title: 'Projects', icon:'mdi-tag-outline', to:"/projects"},
                {title: 'Investments', icon:'mdi-chart-box-outline', to:"/investments" },
                {title: 'Return of investment (ROI)',icon:'mdi-trending-up',to:"/investmentreturn"},
            ],
            role:localStorage.getItem('user_role')
        }
    },
    computed:{
      ...mapGetters('Login',['permissions','loginDetails']),
      loanOptions(){
        const role=localStorage.getItem('user_role');
        let permission= localStorage.getItem('user_permission');
        console.log(role, permission)
        if(role==='1'){
          return this.items.slice(0,4)
        }else{
          let perm=[]
          perm.push(this.items[0])
          if(this.permissions.people){
            perm.push(this.items[1])
          }
          if(this.permissions.transaction){
            perm.push(this.items[2])
          }
          if(this.permissions.report){
            perm.push(this.items[3])
          }
          return perm;
        }
      },
      investmentOptions(){
        const role=localStorage.getItem('user_role');
        if(role==='1'){
          return this.items.slice(4)
        }else{
          let perm=[]
          if(this.permissions.project){
            perm.push(this.items[4])
          }
          if(this.permissions.investment){
            perm.push(this.items[5])
          }
          if(this.permissions.return_of_investment){
            perm.push(this.items[6])
          }
          return perm;
        }
      }
    },

}
</script>

<style>

</style>