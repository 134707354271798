<template>
    <div>
        <TopNav/>
        <div class="mt-10" transition="fade-transition">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import TopNav from './topnav.vue'
export default {
    components:{
        TopNav
    },
    mounted() {
    const isToken = localStorage.getItem('token');
    
    if (!isToken) {
      this.$router.push('/login');
    }
  },
}
</script>

<style>

</style>