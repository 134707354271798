<template>
  <v-app>
    <v-main class="grey lighten-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted() {
    const isToken = localStorage.getItem('token');
    
    if (!isToken) {
      this.$router.push('/login');
    }
  },
  data: () => ({
  }),
};
</script>
