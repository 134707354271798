import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Login from "./Authorization/login";
Vue.use(Vuex);
export const store = new Vuex.Store({
  modules:{
    Login,
  },
  plugins: [createPersistedState()],
});
